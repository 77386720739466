import loadable from '@loadable/component';
import GipsyApi from 'apis/Gipsy';
import { GipsyInstanceType } from 'global';
import i18n from 'i18n';
import moment from 'moment';
import React, { FC, useState } from 'react';
import styles from './LanguageSelect.module.css';

const SpainSvg = loadable(() => import('images/flags/es'));
const PortugalSvg = loadable(() => import('images/flags/pt'));
const UnitedKingdomSvg = loadable(() => import('images/flags/en'));

const LanguageSelect: FC = () => {
  const flagIconRef = React.useRef<HTMLDivElement>(null);
  const [showFlags, setShowFlags] = useState<boolean>(false);

  const changeLanguage = (language: string) => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    setShowFlags(false);
    GipsyApi.defaults.params = { ...GipsyApi.defaults.params, locale: language };

    moment.locale(language);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (flagIconRef.current?.contains(e.target as Node)) return;

    setShowFlags(false);
  };

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  });

  const currentFlag = () => {
    switch (i18n.language) {
      case 'en':
        return (
          <React.Suspense fallback={null}>
            <UnitedKingdomSvg />
          </React.Suspense>
        );
      case 'pt':
        return (
          <React.Suspense fallback={null}>
            <PortugalSvg />
          </React.Suspense>
        );
      case 'es':
        return (
          <React.Suspense fallback={null}>
            <SpainSvg />
          </React.Suspense>
        );
      default:
        return <UnitedKingdomSvg />;
    }
  };

  return (
    <div className={styles.select}>
      <div onClick={() => setShowFlags(!showFlags)} className={styles.currentFlag} ref={flagIconRef}>
        {currentFlag()}
      </div>
      {showFlags && (
        <div className={styles.flags}>
          {process.env.REACT_APP_INSTANCE_TYPE !== GipsyInstanceType.BRAZIL && i18n.language !== 'en' && (
            <div onClick={() => changeLanguage('en')}>
              <React.Suspense fallback={null}>
                <UnitedKingdomSvg />
              </React.Suspense>
            </div>
          )}
          {process.env.REACT_APP_INSTANCE_TYPE !== GipsyInstanceType.BRAZIL && i18n.language !== 'pt' && (
            <div onClick={() => changeLanguage('pt')}>
              <React.Suspense fallback={null}>
                <PortugalSvg />
              </React.Suspense>
            </div>
          )}
          {process.env.REACT_APP_INSTANCE_TYPE !== GipsyInstanceType.BRAZIL && i18n.language !== 'es' && (
            <div onClick={() => changeLanguage('es')}>
              <React.Suspense fallback={null}>
                <SpainSvg />
              </React.Suspense>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
