import cx from 'classnames';
import { GipsyInstanceType } from 'global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import whatsAppImg from 'images/logos/whatsapp.png';

import styles from './index.module.css';

const WhatsApp: React.VFC = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);

  const whatsAppPhone =
    process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL
      ? 'https://api.whatsapp.com/send?phone=351913568693'
      : 'https://api.whatsapp.com/send?phone=556121067106';

  if (location.pathname.includes('/checkout')) {
    return null;
  }

  return (
    <div className={cx(styles.container, { [styles.scaleDown]: !open })}>
      <a target="_blank" rel="noopener noreferrer" href={whatsAppPhone}>
        <img src={whatsAppImg} alt="WhatsApp logo" width="24px" height="24px" />
        {open && <span>{t('views:whatsapp.message')}</span>}
      </a>
    </div>
  );
};

export default WhatsApp;
