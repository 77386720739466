import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './index.module.css';

type SpinnerProps = {
  isLoading?: boolean;
};

const Spinner: React.VFC<SpinnerProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getMessages = () => {
    if (location.pathname.includes('checkout')) {
      if (queryParams.has('purchase')) {
        return {
          primary: t('views:loading.checkoutPurchaseConfirmation.message1'),
          secondary: t('views:loading.checkoutPurchaseConfirmation.message2'),
        };
      }

      return {
        primary: t('views:loading.searchResultForCheckout.message1'),
        secondary: t('views:loading.searchResultForCheckout.message2'),
      };
    }
    if (location.pathname.includes('search')) {
      if (queryParams.has('seatSelection')) {
        return {
          primary: t('views:loading.searchSeatSelection.message1'),
          secondary: t('views:loading.searchSeatSelection.message1'),
        };
      }
      if (queryParams.has('seatSelected')) {
        return {
          primary: t('views:loading.searchResultForCheckout.message1'),
          secondary: t('views:loading.searchResultForCheckout.message2'),
        };
      }
      return {
        primary: t('views:loading.searchForSearchResults.message1'),
        secondary: t('views:loading.searchForSearchResults.message2'),
      };
    }
    return { primary: t('views:loading.default'), secondary: '' };
  };

  const { primary: primaryMessage, secondary: secondaryMessage } = getMessages();

  return (
    <div className={cx({ [styles.container]: isLoading, [styles.notVisible]: !isLoading })}>
      <div className={cx('spinner-border', styles.spinner)} role="status" />
      <div className={styles.messages}>
        <span className={styles.message1}>{primaryMessage}</span>
        {secondaryMessage && <span className={styles.message2}>{secondaryMessage}</span>}
      </div>
    </div>
  );
};

export default Spinner;
